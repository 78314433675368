import React from 'react';
import SEO from "components/Seo";
import Header from "components/Header";
import Footer from "components/Footer";

import LayoutMain from 'components/layout/layoutMain';
import LayoutPanel from 'components/layout/layoutPanel';
import { modalContents } from 'constants/modal-content';
import { LinkModal } from 'kh-common-components';

import Link from 'components/common/Link';
import RacNgProductHighlight from 'components/cards/RacNgProductHighlight';

import racLogo from 'images/rac/natural-gas/kleenheat-rac-logo.png';
import heroBannerSoap from 'images/rac/natural-gas/hero-banner-soap.png';

import 'styles/pages/rac-offer-ended.css';

function RacOfferEndedPage() {

    return (
		<>
            <SEO title="RAC Offer Ended | Natural Gas – Kleenheat" 
                 titleBespoke="true"
            	 description="RAC limited time offer now ended. Enjoy ongoing savings with our RAC member discount. Terms and conditions apply."
                 meta={[{name:"robots", content:'noindex'}]}
        	/> 
 
            <Header />

            <LayoutMain>

                <LayoutPanel theme="mer-theme--cyan"
                    		 background="mer-bg--primary-cyan"
                    		 padding="mer-py-lg">
	                <div className="container rac-ng-hero-banner">
	                    <div className="row mer-panel">
	                        <div className="col-12 col-md-10 col-lg-5 mer-text--size-lg offset-lg-1 order-2 py-0">
                                <div className="row">
                                    <div className="col-8 col-sm-6 col-lg-10 col-xl-8 mer-panel__images mx-auto ml-lg-n4 mb-0 p-0">
                                    <img src={racLogo}
                                        className="mer-panel__image kleenheat-rac-logo"
                                        alt="Kleenheat and RAC logo" />
                                    </div>

                                    <div className="col-12 col-sm-8 col-lg-10 text-center text-lg-left mx-auto ml-lg-0 p-0">
                                        <h1 className="mer-typography--headline2 mt-0">Offer ended.</h1>
                                        <p><strong>Enjoy ongoing value. RAC members can save an additional 3% off natural gas usage charges.<sup>*</sup></strong></p>
                                    </div>
                                    <div className="col-12 col-sm-8 col-lg-10 text-center text-lg-left mx-auto ml-lg-0 p-0">
                                        <div className="mer-panel__actions mer-button-group justify-content-center justify-content-lg-start col-lg-5 mx-auto ml-lg-0 p-0 mt-0">
                                            <Link text="Learn more"
                                                link="/rac"
                                                linkClass="mer-width-100 
                                                mer-button 
                                                mer-button--primary" />
                                        </div>

                                        <p className="mer-typography--body3 mt-4">
                                            <LinkModal 
                                                modalContents={modalContents}
                                                useModal="true"
                                                displayContent="*Terms and conditions apply."
                                                title="RAC offer terms and conditions"
                                                modalTitle="RAC offer terms and conditions"
                                                modalContentName="rac-offer-end-terms"
                                                okayButtonLabel="Close"
                                            />
                                        </p>		
                                    </div>
                                </div>
                            </div>

	                        <div className="mer-panel__images col-8 col-md-5 col-lg-4 order-1 py-0">
	                            <img src={heroBannerSoap} 
	                                 className="mer-panel__image hero-banner-soap"
	                                 alt="Flowmoji soap" />
	                        </div>
	                    </div>
	                </div>
	            </LayoutPanel>

                <RacNgProductHighlight panelBackground="mer-bg--ui-light"
								     panelPadding="mer-py-lg" />

            </LayoutMain>
            <Footer />

        </>
    )
}

export default RacOfferEndedPage;